import {
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';

import { useNavigate, useParams } from 'react-router-dom';
import { useRegistry } from '../../../services/GlobalRegistry';
import { OperatorLink } from '../../operators/elements/OperatorLink';
import { HolderLink } from '../../holders/elements/HolderLink';
import { useChargeLocation, WithChargeLocation } from '../../wrappers/WithChargeLocation';
import { useMemo } from 'react';
import { useFilteredLocationIds } from '../hooks/useFilteredLocationIds';
import MobileOffRounded from '@mui/icons-material/MobileOffRounded';
import { ChargeLocationPrivate } from '../elements/ChargeLocationPrivate';
import { ChargeLocationLink } from '../elements/ChargeLocationLink';

function ChargeLocationItem() {
    const navigate = useNavigate();
    const location = useChargeLocation();
    const { operatorId } = useParams();

    return (
        <TableRow
            key={location._id}
            onClick={() => navigate(`/${operatorId}/locations/${location._id}`)}
            hover={true}
            sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none' }}
        >
            <TableCell>
                <Stack direction={'row'} alignItems="center" spacing={1}>
                    <ChargeLocationLink id={location._id} />
                    {!location.publish && <ChargeLocationPrivate />}
                </Stack>
            </TableCell>
            <TableCell>{location.address}</TableCell>
            <TableCell>{location.chargePoints.length}</TableCell>
            <TableCell>
                <OperatorLink id={location.operator} />
            </TableCell>
            <TableCell>
                <HolderLink id={location.holder} />
            </TableCell>
        </TableRow>
    );
}

interface ChargeLocationTableProps {
    locationIds: string[];
}
function ChargeLocationTable({ locationIds }: ChargeLocationTableProps) {
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Название</TableCell>
                        <TableCell>Адрес</TableCell>
                        <TableCell>Количество станций</TableCell>
                        <TableCell>Оператор</TableCell>
                        <TableCell>Владелец</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {locationIds.map(locationId => (
                        <WithChargeLocation key={locationId} id={locationId}>
                            <ChargeLocationItem />
                        </WithChargeLocation>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export interface Filter {
    operator?: string;
    holder?: string;
}

interface Props {
    filter?: Filter;
}
export default function ChargeLocationListTableComponent({ filter = {} }: Props) {
    const filteredLocationIds = useFilteredLocationIds(filter);

    return <ChargeLocationTable locationIds={filteredLocationIds} />;
}
