import { useRegistry } from '../../../services/GlobalRegistry';

interface ChargeLocationAddressProps {
    id: string;
}

export function ChargeLocationAddress({ id }: ChargeLocationAddressProps) {
    const location = useRegistry(store => store.chargeLocations[id]);

    return <>{location?.address || ''}</>;
}
